
















































































import Vue from 'vue'
import { VueEditor, Quill } from 'vue2-editor'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'

import { data2tree } from '../../utils/tree'

Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)

export default Vue.extend({
  props: {
    id: String,
  },
  data() {
    return {
      model: {
        title: '',
        slug: '',
        description: '',
        keywords: '',
        content: '',
        post_status: 1,
        categories: [],
        thumbnail: '',
      },
      categories: [],
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
        },
      },
    }
  },
  created() {
    this.id && this.fetch()
    this.getCategories()
  },
  computed: {
    getThumbnail(): Array<{ name: string; url: string } | null> {
      if (this.model.thumbnail) {
        return [
          {
            name: 'thumbnail',
            url: this.model.thumbnail,
          },
        ]
      }
      return []
    },
  },
  components: {
    VueEditor,
  },
  methods: {
    async fetch() {
      const { data: post } = await this.$http.get(`posts/${this.id}`)
      if (post.categories && post.categories.length) {
        post.categories = post.categories.map((i: any) => i.id)
      }
      this.model = Object.assign(this.model, post)
    },
    async getCategories() {
      const { data: categories } = await this.$http.get('categories?paginate=0')
      this.categories = data2tree(categories)
    },
    async save() {
      if (this.id) {
        await this.$http.put(`posts/${this.id}`, this.model)
      } else {
        await this.$http.post('posts', this.model)
      }
      this.$router.push('/post/list')
      this.$message.success('保存成功')
    },
    async handleImageAdded(
      file: File,
      Editor: any,
      cursorLocation: any,
      resetUploader: any,
    ) {
      var formData = new FormData()
      formData.append('file', file)
      try {
        const res = await this.$http.post('upload', formData)
        Editor.insertEmbed(cursorLocation, 'image', res.data.url)
        resetUploader()
      } catch (error) {
        this.$message.error('文件上传失败，请与管理员联系')
      }
    },
    beforeAvatarUpload(file: File) {
      const isImg = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isImg) {
        this.$message.error('上传头像图片不正确!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isImg && isLt2M
    },
    afterAvatarUpload(res: { url: string }) {
      this.model.thumbnail = res.url
    },
    async handleRemove({ url }: { url: string }) {
      await this.$http.delete(`upload?url=${url}`)
      this.model.thumbnail = ''
    },
  },
})
